import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {createPay, deleteReg, getReg, updateReg, validateReg} from "../api/RegApi";
import {getRegStatusDisplay} from "./regUtils";
import {RegViewSection} from "./RegViewSection";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_STAFF, REG_STATUS_IN_PROGRESS, REG_STATUSES} from "../constants";
import {RegPayEdit} from "./RegPayEdit";
import {RegPayDisplay} from "./RegPayDisplay";
import {RegHistory} from "./RegHistory";
import {showErrorToast, showSuccessToast} from "../utils/usacmUtils";
import {RegCheckout} from "./RegCheckout";
import {Register} from "./Register";
import {RegAssignUser} from "./RegAssignUser";
import {UsacmContext} from "../App";
import {downloadFile} from "../utils/networkUtils";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {RegInvoiceDataEdit} from "./RegInvoiceDataEdit";

export const REG_EDIT_MODE_MAIN = 'reg_edit_main';
export const REG_EDIT_MODE_PAY = 'reg_edit_pay';
export const REG_EDIT_MODE_HISTORY = 'reg_edit_history';
export const REG_EDIT_MODE_CHECKOUT = 'reg_edit_checkout';
export const REG_EDIT_MODE_FIELDS = 'reg_edit_fields';
export const REG_EDIT_MODE_INVOICE_DATA = 'reg_edit_invoice_data';

export function RegEdit({regId, onFinished}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [reg, setReg] = useState(null);
  const [fields, setFields] = useState([]);
  const [pays, setPays] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const regStatusOptions = REG_STATUSES.filter(rs => rs !== REG_STATUS_IN_PROGRESS).map(rs => ({id: rs, value: getRegStatusDisplay(rs)}));
  const [regStatus, setRegStatus] = useState('');
  const [adminComments, setAdminComments] = useState('');
  const [selectedPayId, setSelectedPayId] = useState(null);
  const [selectedPay, setSelectedPay] = useState(null);
  const [mode, setMode] = useState(REG_EDIT_MODE_MAIN);
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
  const [errors, setErrors] = useState([]);
  const [attended, setAttended] = useState(false);
  const regUseAttendance = !!conf?.reg_use_attendance;

  function loadReg() {
    getReg(regId, (code, data, errors) => {
      if (code === 200) {
        setReg(data?.reg);
        setFields(data?.fields || []);
        setPays(data?.pays || []);
        setRegStatus(data?.reg?.reg_status);
        setAttended(!!data?.reg?.attended);
        setAdminComments(data?.reg?.admin_comments || '');
      }
    });
  }

  useEffect(() => {
    loadReg();
  }, [regId]);

  function callUpdateReg() {
    updateReg(regId, regStatus, attended, adminComments, (code, data, errors) => {
      if (code === 200) {
        setReg(data?.reg);
        setFields(data?.fields || []);
        setPays(data?.pays || []);
        setRegStatus(data?.reg?.reg_status);
        setAttended(!!data?.reg?.attended);
        setAdminComments(data?.reg?.admin_comments || '');
        showSuccessToast('Registration updated successfully.');
      }
    });
  }

  function backToMain() {
    setMode(REG_EDIT_MODE_MAIN);
    setSelectedPayId(null)
    setSelectedPay(null);
    loadReg();
  }

  function enterEditPayMode(payId) {
    setSelectedPayId(payId);
    setMode(REG_EDIT_MODE_PAY);
  }

  function enterCheckoutMode(payId) {
    const pay = pays.find(p => p.id === payId);
    if (!pay) {
      console.warn('Unable to find pay with id ' + payId + ' in ', pays);
      return;
    }
    setSelectedPayId(payId);
    setSelectedPay(pay);
    setMode(REG_EDIT_MODE_CHECKOUT);
  }

  function enterEditFieldsMode() {
    // Call validate so all the actions run. This will ensure the fields look correct for the current logged in user
    // If an action has "If admin editing" we need to run that before we can display the fields correctly
    if (reg) {
      validateReg(reg.id, (code, data, errors) => {
        // We don't care if the validation succeeds or fails, we just need the actions to run
      });
    }
    setMode(REG_EDIT_MODE_FIELDS);
  }

  function addPayment() {
    createPay(regId, (code, data, errors) => {
      if (code === 200) {
        if (data?.id) {
          setSelectedPayId(data.id);
          setMode(REG_EDIT_MODE_PAY);
        } else {
          showErrorToast('Failed to create pay.');
        }
      }
    });
  }

  function showDeleteConfirm() {
    setShowingDeleteConfirm(true);
    setErrors([]);
  }

  function callDeleteReg() {
    deleteReg(reg.id, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Registration deleted.');
        if (onFinished) {
          onFinished();
        }
      } else {
        setErrors(errors);
      }
    });
  }

  function editInvoiceData() {
    setMode(REG_EDIT_MODE_INVOICE_DATA);
  }

  return (
    <Container fluid className="usacm-container-wide">
      {mode === REG_EDIT_MODE_MAIN &&
        <Fragment>
          <div className="section-header"> Edit Registration</div>
          <div className='p-2'>
            <span className='fw-bold'>Registration ID:</span>
            <span className='ps-2'>{reg?.id}</span>
          </div>

          <div className='p-2'>
            <span className='fw-bold'>Registration Status:</span>
            <span className='ps-2 d-inline-block'>
              <Form.Group controlId='status-change' className="flex-grow-1">
                <Form.Control
                  className="form-select"
                  as="select"
                  value={regStatus}
                  onChange={e => setRegStatus(e.target.value)}>
                  {regStatusOptions.map(o => {
                    return <option value={o.id} key={o.id}>{o.value}</option>
                  })}
                </Form.Control>
              </Form.Group>
            </span>
          </div>

          {regUseAttendance &&
            <div className='p-2'>
              <span className='fw-bold'>Attendance: </span>
              <span className='ps-2 d-inline-block'>
                 <Form.Check
                   type="checkbox"
                   label="Attended"
                   id="attended"
                   checked={attended}
                   onChange={e => isStaffOrAdmin && setAttended(e.target.checked)}
                 />
              </span>
            </div>
          }

          {reg?.create_user_id !== reg?.assigned_user_id &&
            <div className='p-2'>
              <span className='fw-bold'>Created By:</span>
              <span className='ps-2'>{reg?.create_user_name}, {reg?.create_user_email}</span>
            </div>
          }

          {isStaffOrAdmin &&
            <div className='p-2'>
              <div className='fw-bold text-nowrap'>Admin Comments:</div>
              <div className='w-100'>
                <Form.Control as="textarea"
                              rows={5}
                              placeholder='Admin Comments...'
                              name='admin_comments'
                              value={adminComments}
                              onChange={e => setAdminComments(e.target.value)}/>
              </div>
            </div>
          }

          <Row>
            <Col className="usacm-button-row">
              <Button onClick={() => callUpdateReg()}>Save Status and Comments</Button>
            </Col>
          </Row>

          {conf?.reg_allow_assign &&
            <RegAssignUser reg={reg} setReg={setReg}/>
          }

          <RegViewSection reg={reg} fields={fields} parentFieldId={null}/>

          {pays.map(pay =>
            <div key={pay.id} className='mb-3'>
              <RegPayDisplay pay={pay}
                             onEdit={() => enterEditPayMode(pay.id)}
                             onDeleteFinished={() => backToMain()}
                             onCheckout={() => enterCheckoutMode(pay.id)}/>
            </div>
          )}

          <Row>
            <Col className="usacm-button-row">
              <Button variant="secondary" onClick={() => onFinished()}>Back to List</Button>
              <Button variant="primary" onClick={() => setMode(REG_EDIT_MODE_HISTORY)}>Show History</Button>
              {isStaffOrAdmin &&
                <Button onClick={() => editInvoiceData()}>Invoice Data</Button>
              }
              {isStaffOrAdmin &&
                <Button onClick={() => downloadFile('reg/' + regId + '/invoice-pdf/', 'invoice.pdf')}>Invoice</Button>
              }
              {regUseAttendance && reg?.attended &&
                <Button onClick={() => downloadFile('reg/' + regId + '/cert-of-attend/pdf', 'certificate_of_attendance.pdf')}>
                  Certificate of Attendance
                </Button>
              }
              <Button onClick={() => enterEditFieldsMode()}>Edit Fields</Button>
              <Button onClick={() => addPayment()}>Add Payment</Button>
              {isStaffOrAdmin &&
                <Button variant='danger' className='ms-3' onClick={() => showDeleteConfirm()}>Delete Reg</Button>
              }
            </Col>
          </Row>
        </Fragment>
      }

      {mode === REG_EDIT_MODE_FIELDS && reg &&
        <div>
          <Register preview={false} regId={reg.id} onFinished={backToMain} showCheckout={false}/>
        </div>
      }


      {mode === REG_EDIT_MODE_PAY && selectedPayId &&
        <div>
          <RegPayEdit payId={selectedPayId} onFinished={() => backToMain()}/>
        </div>
      }

      {mode === REG_EDIT_MODE_CHECKOUT && selectedPayId &&
        <RegCheckout reg={reg}
                     pay={selectedPay}
                     preview={false}
                     onCancel={backToMain}
                     onComplete={backToMain}
                     redirectPath='/reg-list/'
                     onFinished={backToMain}/>
      }

      {mode === REG_EDIT_MODE_HISTORY &&
        <div>
          <RegHistory regId={reg?.id} onFinished={() => backToMain()}/>
        </div>
      }

      {mode === REG_EDIT_MODE_INVOICE_DATA &&
        <div>
          <RegInvoiceDataEdit regId={reg?.id} onFinished={() => backToMain()}/>
        </div>
      }

      <Modal show={showingDeleteConfirm} onHide={() => setShowingDeleteConfirm(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            Are you sure you want to delete this registration?
          </div>
          <div className='mb-3'>
            This action cannot be undone. All payments and fields associated with this registration will be lost.
          </div>
          {fieldHasErrors(errors, '') &&
            <div className="usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingDeleteConfirm(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => callDeleteReg()}>Delete Reg</Button>
        </Modal.Footer>
      </Modal>

    </Container>);
}
