import {Button, Col, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {getReg, updateRegInvoiceData} from "../api/RegApi";
import {HtmlEditor} from "../shared/HtmlEditor";
import {showErrorToast, showSuccessToast} from "../utils/usacmUtils";

export function RegInvoiceDataEdit({regId, onFinished}) {
  const [reg, setReg] = useState(null);
  const [fields, setFields] = useState([]);
  const editorRef = useRef(null);


  function loadReg() {
    getReg(regId, (code, data, errors) => {
      if (code === 200) {
        setReg(data?.reg);
        setFields(data?.fields || []);
      }
    });
  }

  useEffect(() => {
    loadReg();
  }, [regId]);


  function getDefaultInvoiceData() {
    if (!reg) {
      return '';
    }
    if (reg?.invoice_data) {
      return reg?.invoice_data;
    }
    if (!fields) {
      return '';
    }
    let html = '';

    const today = new Date();
    const today_str = today.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})
    html += `<div style='margin-top:5px'><b>DATE:</b><span style='margin-left:10px'>${today_str}</span></div>`

    html += `<div style='margin-top:5px'><b>REGISTRANT:</b><span style='margin-left:10px'>${reg.assigned_user_name}</span></div>`

    for (const field of fields) {
      if (field.show_on_invoice) {
        const name = field.name.toUpperCase();
        const value = field.answer?.value;
        html += `<div style='margin-top:5px'><b>${name}:</b><span style='margin-left:10px'>${value}</span></div>`
      }
    }
    return html;
  }

  function updateInvoiceData() {
    const descHtml = editorRef.current.getContent();
    updateRegInvoiceData(regId, descHtml, (code, data, errors) => {
      if (code === 200) {
        setReg(data?.reg);
        setFields(data?.fields || []);
        showSuccessToast('Invoice data updated successfully.');
        onFinished();
      } else {
        showErrorToast('Failed to update invoice data.');
      }
    });
  }

  return (
    <Fragment>
      <div className="section-header"> Edit Invoice Data</div>
      <p className='mt-2'>
        Any data entered into this HTML field will overwrite the data fields that come from the reg on the invoice.
      </p>
      <p className='mt-2'>
        Set this HTML field to blank in order to go back to using the fields from the reg.
      </p>
      <div className='mt-2'>
        <HtmlEditor
          initialValue={getDefaultInvoiceData()}
          onInit={(evt, editor) => editorRef.current = editor}
        />

      </div>
      <Row className='mt-2'>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onFinished()}>Cancel</Button>
          <Button onClick={() => updateInvoiceData()}>Update Invoice Data</Button>
        </Col>
      </Row>

    </Fragment>
  );
}

