// noinspection JSUnusedGlobalSymbols

import {get, post} from '../utils/networkUtils';

/**
 * Updates the editable fields on a reg.
 */
export function updateRegSettings(regStart, regEnd, regAllowAssign, regAllowMultiple, regAllowPayOffline, regUseAttendance,
                                  administeredBy, wireTransferBank, wireTransferAccountHolder, wireTransferRouting, wireTransferAccount,
                                  onComplete) {
  const postData = {
    'reg_start': regStart,
    'reg_end': regEnd,
    'reg_allow_assign': regAllowAssign,
    'reg_allow_multiple': regAllowMultiple,
    'reg_allow_pay_offline': regAllowPayOffline,
    'reg_use_attendance': regUseAttendance,
    'administered_by': administeredBy,
    'wire_transfer_bank': wireTransferBank,
    'wire_transfer_account_holder': wireTransferAccountHolder,
    'wire_transfer_routing': wireTransferRouting,
    'wire_transfer_account': wireTransferAccount,
  };
  post('reg/settings/', postData, onComplete);
}

/**
 * Gets a single reg with all the fields and pays
 */
export function getReg(regId, onComplete) {
  get(`reg/${regId}/`, onComplete);
}

/**
 * Gets an array of regs assigned to the logged in user
 */
export function getRegsMe(onComplete) {
  get(`reg/me/`, onComplete);
}

/**
 * Creates a new reg
 * @param preview If true this will create a preview, if false a real registration
 * @param timeTravelDate create preview as of this date. (only works for preview)
 * @param onComplete callback
 */
export function createReg(preview, timeTravelDate, onComplete) {
  const postData = {'preview': preview, 'time_travel_date': timeTravelDate};
  post('reg/create/', postData, onComplete);
}

/**
 * Updates the assigned user on a reg - by email lookup
 */
export function updateRegAssignedUser(regId, email, force, onComplete) {
  const postData = {
    'reg_id': regId,
    'email': email,
    'force': force,
  };
  post('reg/update-assigned-user/', postData, onComplete);
}

/**
 * Updates the editable fields on a reg.
 */
export function updateReg(regId, regStatus, attended, adminComments, onComplete) {
  const postData = {
    'reg_id': regId,
    'reg_status': regStatus,
    'attended': attended,
    'admin_comments': adminComments,
  };
  post('reg/update/', postData, onComplete);
}

/**
 * Updates the HTML invoice_data field on a reg (single field update)
 */
export function updateRegInvoiceData(regId, invoiceData, onComplete) {
  const postData = {
    'reg_id': regId,
    'invoice_data': invoiceData,
  };
  post('reg/update/invoice_data/', postData, onComplete);
}


/**
 * Deletes a reg.
 * @param regId  id of reg to delete
 * @param onComplete callback
 */
export function deleteReg(regId, onComplete) {
  const postData = {
    'reg_id': regId
  }
  post('reg/delete/', postData, onComplete);
}

/**
 * Validates all fields in a reg
 * @param regId  id of reg to validate
 * @param onComplete callback (contains errors AND data)
 */
export function validateReg(regId, onComplete) {
  const postData = {
    'reg_id': regId,
  }
  post('reg/validate/', postData, onComplete);
}

/**
 * Marks a reg as attended
 returns the reg that was updated
 */
export function regAttended(regId, onComplete) {
  const postData = {'reg_id': regId};
  post('reg/attended/', postData, onComplete);
}

/**
 * Gets a list of all the regs available to the user
 */
export function getRegs(couponIdFilter, onComplete) {
  const postData = {
    'coupon_id_filter': couponIdFilter,
  }
  post('reg/list/', postData, onComplete);
}

/**
 * Gets all the registration template fields
 */
export function getTemplateFields(onComplete) {
  get('reg/field/template/', onComplete);
}

/**
 * Gets a single field
 */
export function getField(fieldId, onComplete) {
  get('reg/field/' + fieldId + '/', onComplete);
}

/**
 * Creates a pay
 */
export function createPay(regId, onComplete) {
  const postData = {
    reg_id: regId,
  }
  post('reg/pay/create/', postData, onComplete);
}

/**
 * Gets a single pay
 */
export function getPay(payId, onComplete) {
  get('reg/pay/' + payId + '/', onComplete);
}

/**
 * Create or Update a field
 */
export function updatePay(payId, payType, payStatus, amount, adminComments, invoiceItems, onComplete) {
  const postData = {
    id: payId,
    pay_type: payType,
    pay_status: payStatus,
    amount: amount,
    admin_comments: adminComments,
    invoice_items: invoiceItems,
  }
  post('reg/pay/update/', postData, onComplete);
}

/**
 * Delete a single pay
 */
export function deletePay(payId, onComplete) {
  const postData = {
    pay_id: payId,
  }
  post('reg/pay/delete/', postData, onComplete);
}

/**
 * Create or Update a field
 */
export function upsertField(fieldId, parentFieldId, name, fieldKey, helpText, required, visible, disabled, showInSummary,
                            showOnInvoice, fieldType, config, fieldOrder, onComplete) {
  const postData = {
    id: fieldId,
    parent_field_id: parentFieldId,
    name: name,
    field_key: fieldKey,
    help_text: helpText,
    required: required,
    visible: visible,
    disabled: disabled,
    show_in_summary: showInSummary,
    show_on_invoice: showOnInvoice,
    field_type: fieldType,
    config: config,
    field_order: fieldOrder,
  }
  post('reg/field/upsert/', postData, onComplete);
}

/**
 * Delete a field
 */
export function deleteField(fieldId, onComplete) {
  const postData = {
    id: fieldId,
  }
  post('reg/field/delete/', postData, onComplete);
}

/**
 * Swaps the field order of two fields
 */
export function swapFieldOrder(fieldIdA, fieldIdB, onComplete) {
  const postData = {
    field_id_a: fieldIdA,
    field_id_b: fieldIdB,
  }
  post('reg/field/swap-order/', postData, onComplete);
}

/**
 * Sets the answer data on a field
 * This will return data:null if updating the field caused no change (no actions ran)
 * This will return data: [field, field] all the fields in the reg if at least one action ran
 */
export function updateFieldAnswer(fieldId, answer, onComplete) {
  const postData = {
    'field_id': fieldId,
    'answer': answer,
  }
  post('reg/field/answer/', postData, onComplete);
}

/**
 * Gets all the fields that have abstracts associated
 */
export function getFieldsWithAbstracts(onComplete) {
  get('reg/field/abstract/list/', onComplete);
}

/**
 * Gets all the actions in this conf
 */
export function getActions(onComplete) {
  get('reg/action/', onComplete);
}

/**
 * Create or Update a field
 */
export function upsertAction(actionId, conditionAnd, customAction, actionOrder, conditions, results, elseResults, onComplete) {
  const postData = {
    id: actionId,
    condition_and: conditionAnd,
    action_order: actionOrder,
    custom_action: customAction,
    conditions: conditions,
    results: results,
    else_results: elseResults,
  }
  post('reg/action/upsert/', postData, onComplete);
}

/**
 * Delete an action
 */
export function deleteAction(actionId, onComplete) {
  const postData = {
    id: actionId,
  }
  post('reg/action/delete/', postData, onComplete);
}

/**
 * Swaps the action order of two actions
 */
export function swapActionOrder(actionAId, actionBId, onComplete) {
  const postData = {
    action_id_a: actionAId,
    action_id_b: actionBId,
  }
  post('reg/action/swap-order/', postData, onComplete);
}

/**
 * Gets an array of reg history objects
 */
export function getRegHistory(regId, onComplete) {
  get('reg/' + regId + '/history/', onComplete);
}

/**
 * Gets reg history objects (across reg)
 */
export function getRegHistoryAll(showAllConf, filterUserId, dateFilterStart, dateFilterEnd, regId, action, pageNum, pageSize, onComplete) {
  const data = {
    'show_all_conf': showAllConf,
    'filter_user_id': filterUserId,
    'date_filter_start': dateFilterStart,
    'date_filter_end': dateFilterEnd,
    'reg_id': regId,
    'action': action,
    'page_num': pageNum,
    'page_size': pageSize,
  };
  post('reg/history/all/', data, onComplete);
}

/**
 * Gets all the regHistory actions used
 */
export function getRegHistoryActions(onComplete) {
  get(`reg/history/actions/`, onComplete);
}

/**
 * Gets all the coupons in the conf
 */
export function getCoupons(onComplete) {
  get(`reg/coupon/list/`, onComplete);
}

/**
 * Determine if coupons with codes exist for this user
 */
export function getCouponCodesExist(regId, onComplete) {
  get(`reg/${regId}/coupon/code/exist/`, onComplete);
}

/**
 * Applies a coupon by code
 * returns pay object, or errors if code is not valid
 */
export function applyCouponCode(payId, code, onComplete) {
  const postData = {
    pay_id: payId,
    code: code,
  }
  post('reg/coupon/code/apply/', postData, onComplete);
}

/**
 * Create or Update a coupon
 */
export function upsertCoupon(couponId, active, totalCount, unlimitedCount, code, allowedUsers, startDate, endDate,
                             invoiceKey, invoicePercent, invoiceAmount, invoiceText, invoiceOrder, onComplete) {
  const postData = {
    id: couponId,
    active: active,
    total_count: totalCount,
    unlimited_count: unlimitedCount,
    code: code,
    allowed_users: allowedUsers,
    start_date: startDate,
    end_date: endDate,
    invoice_key: invoiceKey,
    invoice_percent: invoicePercent,
    invoice_amount: invoiceAmount,
    invoice_text: invoiceText,
    invoice_order: invoiceOrder,
  }
  post('reg/coupon/upsert/', postData, onComplete);
}

/**
 * Deletes a coupon.
 */
export function deleteCoupon(couponId, onComplete) {
  const postData = {
    id: couponId,
  }
  post('reg/coupon/delete/', postData, onComplete);
}
