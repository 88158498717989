/* eslint-disable react-hooks/exhaustive-deps */
import "./App.scss"
import './global_styles.scss';
import React, {useEffect, useState} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import {Home} from "./user/Home";
import {Dashboard} from "./user/Dashboard";
import axios from "axios";
import {MainMenu} from "./menu/MainMenu";
import {getCurrentUser, hasPermission, isEmailVerified, isUserLoggedIn, setAuthHeaders} from "./api/UserApi";
import {Profile} from "./user/Profile";
import {EditProfile} from "./user/EditProfile";
import {ChangePassword} from "./user/ChangePassword";
import {VerifyEmail} from "./user/VerifyEmail";
import {ForgotPasswordReset} from "./user/ForgotPasswordReset";
import {ForgotPasswordSend} from "./user/ForgotPasswordSend";
import {library} from '@fortawesome/fontawesome-svg-core'
import {getCurrentConf} from "./api/ConfApi";
import {ConfSettingsTabs} from "./conf/ConfSettingsTabs";
import {LOCAL_HOSTS, PERMISSION_CONF_ADMIN, PERMISSION_CONF_ORGANIZER, PERMISSION_STAFF} from "./constants";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SubmitAbstract} from "./abstract/SubmitAbstract";
import {SymposiumPropose} from "./symposium/SymposiumPropose";
import {ConfUserList} from "./conf/ConfUserList";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Theme grid CSS
import {setThemeVariables} from "./utils/usacmUtils";
import {SymposiumList} from "./symposium/SymposiumList";
import {AbstractList} from "./abstract/AbstractList";
import {RoomList} from "./room/RoomList";
import {RoomUpsert} from "./room/RoomUpsert";
import {SessionList} from "./schedule/SessionList";
import {Schedule} from "./schedule/Schedule";
import {CreateConf} from "./admin/CreateConf";
import {ConfList} from "./admin/ConfList";
import {Faq} from "./misc/Faq";
import {OrgInfo} from "./misc/OrgInfo";
import preval from 'preval.macro';
import {BusyOverlay} from "./shared/BusyOverlay";
import {PageNotFound} from "./user/PageNotFound";
import {ReportList} from "./misc/ReportList";
import {SearchSchedule} from "./schedule/SearchSchedule";
import {RegSettingsTabs} from "./reg/RegSettingsTabs";
import {RegList} from "./reg/RegList";
import {CreateAccount} from "./user/CreateAccount";
import {Routes} from "react-router";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faCalendar,
  faCalendarDays,
  faChartLine,
  faCheckDouble,
  faCircle,
  faCircleCheck,
  faCircleMinus,
  faCircleQuestion,
  faCircleRight,
  faCircleXmark,
  faClipboardQuestion,
  faClipboardUser,
  faEnvelopeCircleCheck,
  faFile,
  faFileCirclePlus,
  faFileExcel,
  faFilePdf,
  faFileZipper,
  faGear,
  faHouse,
  faIdCard,
  faKey,
  faMagnifyingGlass,
  faPeopleRoof,
  faPersonChalkboard,
  faPersonShelter,
  faPlus,
  faRegistered,
  faRightFromBracket,
  faRightToBracket,
  faScroll,
  faSpinner,
  faSquarePlus,
  faTrash,
  faUser,
  faUserClock,
  faUserGroup,
  faUserPlus,
  faUsersGear,
  faWrench,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {ExtendedAbstract} from "./abstract/ExtendedAbstract";
import {TrackingTabs} from "./admin/TrackingTabs";
import {ToolsTabs} from "./admin/ToolsTabs";
import {RegAttendanceEntry} from "./reg/RegAttendanceEntry";
// Import all the font-awesome fonts we are going to use in the site (Could do a mass import fas but that's around 1MB!)
library.add(
  faAngleUp,
  faAngleDown,
  faBars,
  faCalendar,
  faCheckDouble,
  faClipboardQuestion,
  faClipboardUser,
  faCalendarDays,
  faChartLine,
  faCircle,
  faCircleCheck,
  faCircleMinus,
  faCircleQuestion,
  faCircleXmark,
  faFileCirclePlus,
  faCircleRight,
  faEnvelopeCircleCheck,
  faFile,
  faFileExcel,
  faFileZipper,
  faFilePdf,
  faGear,
  faHouse,
  faIdCard,
  faKey,
  faMagnifyingGlass,
  faRegistered,
  faPersonChalkboard,
  faPersonShelter,
  faPeopleRoof,
  faPlus,
  faRightFromBracket,
  faRightToBracket,
  faScroll,
  faSpinner,
  faSquarePlus,
  faTrash,
  faUser,
  faUserClock,
  faUsersGear,
  faUserGroup,
  faUserPlus,
  faWrench,
  faXmark,
)
// Setup Axios for local development - need to point to different backend
if (LOCAL_HOSTS[window.location.origin]) {
  axios.defaults.baseURL = LOCAL_HOSTS[window.location.origin];
  axios.defaults.maxRedirects = 0;
}

export const UsacmContext = React.createContext(null);
export const globalVars = {
  openConnections: 0,  // track open network connections
  openConnectionsTimeout: null,  // timeout for open network connections
  confReloadHandle: null, // timeout for conf reload (to keep deadlines fresh)
};
export const CONF_RELOAD_MS = 3600000; // 1 hour - to keep conf data fresh

export default function Usacm() {
  const [userLoggedIn, setUserLoggedIn] = useState(isUserLoggedIn());
  const [emailVerified, setEmailVerified] = useState(isEmailVerified());
  const [conf, setConf] = useState(null);
  const [navChanged, setNavChanged] = useState('');
  const isStaff = hasPermission(PERMISSION_STAFF);
  const isAdmin = hasPermission(PERMISSION_CONF_ADMIN);
  const isConfOrganizer = hasPermission(PERMISSION_CONF_ORGANIZER);
  const isStaffAdminOrg = isStaff || isAdmin || isConfOrganizer;

  useEffect(() => {
    if (conf) {
      setThemeVariables(conf);
    }
  }, [conf]);

  function loadConf() {
    // Even non-logged in users can view conf data
    getCurrentConf((code, data) => {
      if (code === 200) {
        setConf(data);
        document.title = data.short_name;
      }
    });
  }

  // When the app starts up we want to load the axios headers
  // If this is called twice it's not a big deal
  useEffect(() => {
    setAuthHeaders();
    if (userLoggedIn) {
      // Load fresh user data
      getCurrentUser(() => {
        setEmailVerified(isEmailVerified());
      });
    }
    loadConf();
    if (!globalVars.confReloadHandle) {
      globalVars.confReloadHandle = setInterval(() => {
        loadConf();
      }, CONF_RELOAD_MS);
    }
    // preval will be run during the yarn build process (modifies exported bundle)
    console.log('USACM Build ', preval`module.exports = new Date().toLocaleString();`);
  }, []);

  return (
    <BrowserRouter>
      <UsacmContext.Provider value={
        {
          loggedIn: [userLoggedIn, setUserLoggedIn],
          verified: [emailVerified, setEmailVerified],
          conference: [conf, setConf],
          navigationChanged: [navChanged, setNavChanged],
        }
      }>
        <div className="root-container">
          <BusyOverlay/>
          <ToastContainer/>
          <div className="root-left">
            <MainMenu/>
          </div>
          <div className="root-right">
            <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route path="/create-account" element={<CreateAccount/>}/>
              <Route path="/verify-email" element={<VerifyEmail/>}/>
              <Route path="/forgot-password-send" element={<ForgotPasswordSend/>}/>
              <Route path="/forgot-password" element={<ForgotPasswordReset/>}/>

              {userLoggedIn && <Route path="/dashboard" element={<Dashboard/>}/>}
              {userLoggedIn && <Route path="/profile" element={<Profile/>}/>}
              {userLoggedIn && <Route path="/edit-profile" element={<EditProfile/>}/>}
              {userLoggedIn && <Route path="/change-password" element={<ChangePassword/>}/>}
              {userLoggedIn && <Route path="/submit-abstract" element={<SubmitAbstract/>}/>}
              {userLoggedIn && <Route path="/symposium-propose" element={<SymposiumPropose/>}/>}
              {userLoggedIn && <Route path="/symposium-list" element={<SymposiumList/>}/>}
              {userLoggedIn && <Route path="/abstract-list" element={<AbstractList/>}/>}
              {userLoggedIn && <Route path="/reg-list" element={<RegList/>}/>}
              {userLoggedIn && <Route path="/attendance-entry" element={<RegAttendanceEntry/>}/>}
              {userLoggedIn && <Route path="/extended-abstract" element={<ExtendedAbstract/>}/>}
              {userLoggedIn && <Route path="/search-schedule" element={<SearchSchedule/>}/>}
              {userLoggedIn && <Route path="/faq" element={<Faq/>}/>}
              {userLoggedIn && <Route path="/org-info" element={<OrgInfo/>}/>}

              {isStaffAdminOrg && <Route path="/conf-user-list" element={<ConfUserList/>}/>}
              {isStaffAdminOrg && <Route path="/room-list/upsert" element={<RoomUpsert/>}/>}
              {isStaffAdminOrg && <Route path="/room-list" element={<RoomList/>}/>}
              {isStaffAdminOrg && <Route path="/session-list" element={<SessionList/>}/>}
              {isStaffAdminOrg && <Route path="/schedule" element={<Schedule/>}/>}
              {isStaffAdminOrg && <Route path="/reports" element={<ReportList/>}/>}
              {isStaffAdminOrg && <Route path="/reg-settings" element={<RegSettingsTabs/>}/>}
              {isStaffAdminOrg && <Route path="/conf-settings" element={<ConfSettingsTabs/>}/>}

              {isStaff && <Route path="/create-conf" element={<CreateConf/>}/>}
              {isStaff && <Route path="/conf-list" element={<ConfList/>}/>}
              {isStaff && <Route path="/tracking" element={<TrackingTabs/>}/>}
              {isStaff && <Route path="/tools" element={<ToolsTabs/>}/>}

              <Route path="*" element={<PageNotFound/>}/>
            </Routes>
          </div>
        </div>
      </UsacmContext.Provider>
    </BrowserRouter>
  );
}
