import {Button, Col, Container, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {
  ABSTRACT_STATUS_APPROVED,
  CONF_LABEL_KEY_SCHEDULING_INSTRUCTIONS_CONTENT,
  CONF_LABEL_KEY_SYMPOSIUM,
  FLAG_ABSTRACT_FINAL_APPROVED,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF
} from "../constants";
import {getConfLabel, showErrorToast, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {UsacmContext} from "../App";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getAbstractDisplayStatuses} from "../utils/displayUtils";
import {
  getRoomSessionChairs,
  getRoomSessionsForSymposium,
  getRoomSessionTimeslotsForSymposium,
  getSession,
  updateChairs,
  updateSchedulingComplete,
  upsertRoomSessionTimeslots
} from "../api/ScheduleApi";
import {getAbstracts} from "../api/AbstractApi";
import {ForceUpdateButton} from "../shared/ForceUpdateButton";
import {getSymposium} from "../api/SymposiumApi";
import {hasPermission} from "../api/UserApi";
import {downloadFile, getErrorMessages} from "../utils/networkUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function ScheduleAbstract({symposiumId, onClose}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const [allAbstracts, setAllAbstracts] = useState([]); // List of all abstracts (including archived, wrong status etc.)
  const [symposium, setSymposium] = useState(null);
  const [abstracts, setAbstracts] = useState([]); // List of approved abstracts
  const [roomSessions, setRoomSessions] = useState([]);
  const [chairs, setChairs] = useState([]);
  const [newChairName, setNewChairName] = useState('');
  const [newChairEmail, setNewChairEmail] = useState('');
  const [selectedRoomSession, setSelectedRoomSession] = useState(null); // roomSession object
  const [symposiumRoomSessionTimeslots, setSymposiumRoomSessionTimeslots] = useState([]); // all in symposium
  const [roomSessionTimeslots, setRoomSessionTimeslots] = useState([]); // for this roomSession
  const [session, setSession] = useState(null);
  const [errors, setErrors] = useState([]);
  const symposiumLabel = getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM);
  const symposiumLabelUpper = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const [showingInstructions, setShowingInstructions] = useState(false);
  const schedulingInstructionsHtml = getConfLabel(conf, CONF_LABEL_KEY_SCHEDULING_INSTRUCTIONS_CONTENT)
  const useKeynotes = conf?.use_keynotes || false;
  const allowSingleTimeslotKeynotes = (useKeynotes && conf?.allow_single_timeslot_keynotes) || false;
  const [showingSchedulingCompleteConfirm, setShowingSchedulingCompleteConfirm] = useState(false);
  const [schedulingCompleteErrors, setSchedulingCompleteErrors] = useState([]);
  const canEdit = isStaffOrAdmin || !symposium?.scheduling_complete;
  const downloadUrl = `schedule/symposium/${symposiumId}/sessions/excel/`

  function loadSymposium() {
    getSymposium(symposiumId, (code, data, errors) => {
      if (code === 200) {
        setSymposium(data);
      }
    });
  }

  function loadSymposiumRoomSessionTimeslots(newRoomSessions, newRoomSession) {
    getRoomSessionTimeslotsForSymposium(symposiumId, (code, newSrst, errors) => {
      if (code === 200) {
        setSymposiumRoomSessionTimeslots(newSrst);
        loadSession(newRoomSessions, newRoomSession, newSrst);
      }
    });
  }

  function loadRoomSessions() {
    getRoomSessionsForSymposium(symposiumId, (code, newRoomSessions, errors) => {
      if (code === 200) {
        setRoomSessions(newRoomSessions);
        if (newRoomSessions?.length) {
          setSelectedRoomSession(newRoomSessions[0]);
        }
        loadSymposiumRoomSessionTimeslots(newRoomSessions, newRoomSessions[0]);
      }
    });
  }

  function loadAbstracts() {
    getAbstracts(symposiumId, null, (code, data, errors) => {
      if (code === 200) {
        setAllAbstracts(data);
        // Only approved or final approved abstracts
        const approvedAbstracts = data.filter(a => getAbstractDisplayStatuses(a).find(
          status => [ABSTRACT_STATUS_APPROVED, FLAG_ABSTRACT_FINAL_APPROVED].includes(status.key)));
        setAbstracts(approvedAbstracts);
      }
    });
  }

  useEffect(() => {
    loadRoomSessions();
    loadAbstracts();
    loadSymposium();
  }, [symposiumId]);


  // Create all the roomsessiontimeslots for this roomsession
  function createAllRoomSessionTimeslots(newRoomSessions, roomSession, session, newSymposiumRoomSessionTimeslots) {
    if (!newRoomSessions || !roomSession) {
      return;
    }
    const sessionId = roomSession.session_id;
    const roomId = roomSession.room_id;
    const newRoomSessionTimeslots = session?.timeslots?.map(timeslot => ({
      id: null, // roomSessionTimeslot.id (used for update)
      roomsession_id: newRoomSessions.find(rs => rs.session_id === sessionId && rs.room_id === roomId)?.id || null, // used for insert
      timeslot_id: timeslot.id,
      abstract_id: null,
      single_timeslot_keynote: false,
    })) || [];
    // Go through all the roomsessiontimeslots already assigned to abstracts
    for (const rst of newRoomSessionTimeslots) {
      const foundRst = newSymposiumRoomSessionTimeslots.find(s => s.roomsession_id === rst.roomsession_id && s.timeslot_id === rst.timeslot_id);
      if (foundRst) {
        rst.id = foundRst.id;
        rst.roomsession_id = foundRst.roomsession_id;
        rst.abstract_id = foundRst.abstract_id;
        rst.single_timeslot_keynote = foundRst.single_timeslot_keynote;
      }
    }
    setRoomSessionTimeslots(newRoomSessionTimeslots);
  }

  function loadRoomSessionChairs(roomSession) {
    getRoomSessionChairs(roomSession.id, (code, data, errors) => {
      if (code === 200) {
        setChairs(data);
      }
    });
  }

  function loadSession(newRoomSessions, roomSession, newSrst = null) {
    const sessionId = roomSession.session_id;
    getSession(sessionId, (code, data, errors) => {
      if (code === 200) {
        setSession(data);
        createAllRoomSessionTimeslots(newRoomSessions, roomSession, data, newSrst);
      }
    });
    loadRoomSessionChairs(roomSession);
  }

  function chooseRoomSession(newRoomSessionId) {
    setErrors([]);
    const roomSession = roomSessions?.find(rs => '' + rs.id === newRoomSessionId);
    if (!roomSession) {
      console.warn('Unable to find roomSession with id ' + newRoomSessionId + ' in ', roomSessions);
      return;
    }
    setSelectedRoomSession(roomSession);
    loadSession(roomSessions, roomSession, symposiumRoomSessionTimeslots);
  }

  function getRst(timeslotId) {
    const foundRst = roomSessionTimeslots.find(rst => rst.timeslot_id === timeslotId);
    if (!foundRst) {
      console.warn("getRst Unable to find matching roomSessionTimeslot for timeslot_id=" + timeslotId, ' in ', roomSessionTimeslots);
      return {};
    }
    return foundRst;
  }

  function getAssignedAbstractId(timeslotId) {
    return getRst(timeslotId).abstract_id;
  }

  function setAssignedAbstractId(timeslotId, abstractId) {
    getRst(timeslotId).abstract_id = !abstractId ? null : parseInt(abstractId, 10);
    setRoomSessionTimeslots([...roomSessionTimeslots]); // refresh
  }

  function setKeynoteFlag(timeslotId, keynoteChecked) {
    getRst(timeslotId).single_timeslot_keynote = keynoteChecked;
    setRoomSessionTimeslots([...roomSessionTimeslots]); // refresh
  }

  function getKeynoteFlag(timeslotId) {
    return getRst(timeslotId)?.single_timeslot_keynote || false;
  }

  /**
   * Remove abstracts that are already assigned a timeslot
   *  If not useKeynotes then all used abstracts are removed
   *  If ussKeynotes only removes abstracts in a different roomsession
   *  @param timeslotId The timeslot.id whose abstract is never filtered out (current choicelist)
   */
  function getFilteredAbstracts(timeslotId) {
    if (!selectedRoomSession || !symposiumRoomSessionTimeslots) {
      return [];
    }
    // Get all the abstractIds used in other roomSessions (those abstracts are not available here)
    const usedAbstractIds = symposiumRoomSessionTimeslots
    .filter(rst => rst.abstract_id && rst.roomsession_id !== selectedRoomSession.id)
    .map(rst => rst.abstract_id);
    if (!useKeynotes) {
      const thisAbstractId = getRst(timeslotId)?.abstract_id;
      // If keynotes are not allowed, then we won't allow duplicate abstracts at all
      usedAbstractIds.push(...roomSessionTimeslots
      .filter(rst => rst.abstract_id && rst.abstract_id !== thisAbstractId) // the current timeslot uses this abstract
      .map(rst => rst.abstract_id));
    }
    return abstracts.filter(a => !usedAbstractIds.includes(a.id));
  }

  function callUpdate(force = false) {
    setErrors([]);
    upsertRoomSessionTimeslots(roomSessionTimeslots, force, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Updated time slots.');
        loadSymposiumRoomSessionTimeslots(roomSessions, selectedRoomSession);
      } else {
        setErrors(errors);
      }
    });
    // If the user has entered chair data but hasn't pushed "Add" yet
    if (newChairName || newChairEmail) {
      addChair();
    }
    updateChairs(selectedRoomSession.id, chairs, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Updated chairs.');
        // We call this in case the upsertRoomSessionTimeslot failed, then the chairs won't refresh
        loadRoomSessionChairs(selectedRoomSession);
      } else {
        setErrors(errors);
      }
    });
  }

  function deleteChair(index) {
    const chair = chairs[index];
    if (!chair.id) {
      // Doesn't exist in DB
      chairs.splice(index, 1);
    } else {
      // Exists in DB
      chairs[index].deleted = true;
    }
    setChairs([...chairs]); // cause new render
  }

  function editChair(index, field, value) {
    chairs[index][field] = value;
    setChairs([...chairs]); // cause new render
  }

  function addChair() {
    chairs.push({
      'id': null,
      'name': newChairName,
      'email': newChairEmail,
      'roomsession_id': selectedRoomSession.id,
    });
    setNewChairName('');
    setNewChairEmail('');
  }

  function showSchedulingCompleteConfirm() {
    setSchedulingCompleteErrors([]);
    setShowingSchedulingCompleteConfirm(true);
  }

  function callUpdateSchedulingComplete(schedulingComplete) {
    setSchedulingCompleteErrors([]);
    updateSchedulingComplete(symposiumId, schedulingComplete, (code, data, errors) => {
      if (code === 200) {
        if (schedulingComplete) {
          setShowingSchedulingCompleteConfirm(false);
          if (!isStaffOrAdmin) {
            // Wipe any changes to the form the user might have made (since they cannot edit any more)
            loadRoomSessions();
          }
        } else {
          showSuccessToast('Scheduling is now allowed.');
        }
        // We need to reload the symposium to update symposium.scheduling_complete
        loadSymposium();
      } else {
        if (schedulingComplete) {
          setSchedulingCompleteErrors(errors);
        } else {
          // If allowing scheduling (admin only)
          showErrorToast('Failed to allow scheduling ' + getErrorMessages(schedulingCompleteErrors));
        }
      }
    });
  }


  return (
    <Fragment>
      <Container fluid className="usacm-container-wide pb-0">
        <Row>
          <Col className="">
            <Button onClick={() => setShowingInstructions(true)}>Scheduling Instructions</Button>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="col-12 fw-bold text-center">
            {symposiumLabelUpper} #{symposium?.symposium_number} {symposium?.title}
          </Col>
        </Row>
      </Container>

      <Container fluid className="usacm-container-medium pt-0">

        <Row className="mb-3">
          <Col className="col-12">
            <Form.Group controlId="room-session-select">
              <Form.Control
                className="form-select"
                as="select"
                value={selectedRoomSession?.id || ''}
                onChange={e => chooseRoomSession(e.target.value)}
              >
                {roomSessions.map(rs => {
                  return <option value={rs.id} key={rs.id}>
                    {rs.session_name} {rs.session_number} in room #{rs.room_number} {rs.room_name} on {rs.session_date}
                  </option>
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="col-12 mb-1 fw-bold">
            Chair(s)
          </Col>
        </Row>

        {chairs.map((chair, index) => {
          if (chair.deleted) {
            return '';
          }
          return (
            <Row key={'chair_' + index} className="mb-3">
              <Col className="col-5">
                <Form.Group controlId={'chair-name-' + index}>
                  <FloatingLabel controlId={'chair-name-' + index} label={"Name"}>
                    <Form.Control type="text"
                                  name={'chair-name' + index}
                                  value={chairs[index].name}
                                  onChange={e => editChair(index, 'name', e.target.value)}
                                  readOnly={!canEdit}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col className="col-5">
                <Form.Group controlId={'chair-email-' + index}>
                  <FloatingLabel controlId={'chair-email-' + index} label={"Email"}>
                    <Form.Control type="text"
                                  name={'chair-email-' + index}
                                  value={chairs[index].email}
                                  onChange={e => editChair(index, 'email', e.target.value)}
                                  readOnly={!canEdit}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col className="col-2 mt-auto mb-auto">
                {canEdit &&
                  <Button onClick={() => deleteChair(index)}>Delete</Button>
                }
              </Col>
            </Row>);
        })}

        {canEdit &&
          <Row className='mb-3'>
            <Col className="col-5">
              <Form.Group controlId="new-chair-name">
                <FloatingLabel controlId="new-chair-name" label={"Name"}>
                  <Form.Control type="text"
                                name="newChairName"
                                value={newChairName}
                                onChange={e => setNewChairName(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col className="col-5">
              <Form.Group controlId="new-chair-email">
                <FloatingLabel controlId="new-chair-email" label={"Email"}>
                  <Form.Control type="text"
                                name="newChairEmail"
                                value={newChairEmail}
                                onChange={e => setNewChairEmail(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col className="col-2 mt-auto mb-auto">
              <Button onClick={addChair}>Add</Button>
            </Col>
          </Row>
        }


        <Row>
          <Col className="col-2 mb-1 fw-bold">
            Time Slot
          </Col>
          <Col className={"mb-1 fw-bold " + (allowSingleTimeslotKeynotes ? "col-9" : "col-10")}>
            Abstract
          </Col>
          {allowSingleTimeslotKeynotes &&
            <Col className="col-1 mb-1 fw-bold">
              Keynote
            </Col>
          }
        </Row>

        {session?.timeslots?.map(timeslot => {
          const filteredAbstracts = getFilteredAbstracts(timeslot.id);
          const assignedAbstractId = getAssignedAbstractId(timeslot.id);
          if (assignedAbstractId && !filteredAbstracts.find(a => a.id === assignedAbstractId)) {
            const abstract = allAbstracts.find(a => a.id === assignedAbstractId);
            if (abstract) {
              filteredAbstracts.push(abstract);
            } else {
              // If the selected abstract is not in the list of all abstracts we have a problem, perhaps permissions, or deleted, or status
              filteredAbstracts.push({id: assignedAbstractId, abstract_number_full: 'ERROR', title: 'Invalid Abstract'})
            }
          }
          return <Row className="mb-3" key={timeslot.id}>
            <Col className="col-2">
              {timeslot.start_time} - {timeslot.end_time}
            </Col>
            <Col className={allowSingleTimeslotKeynotes ? "col-9" : "col-10"}>
              <Form.Group controlId="abstract-select">
                <Form.Control
                  className="form-select"
                  as="select"
                  value={assignedAbstractId || ''}
                  onChange={e => setAssignedAbstractId(timeslot.id, e.target.value)}
                  disabled={!canEdit}
                >
                  <option value="">Choose Abstract...</option>
                  {filteredAbstracts.map(a => {
                    return <option value={a.id} key={a.id}>
                      #{a.abstract_number_full} {a.title}
                    </option>
                  })}
                </Form.Control>
              </Form.Group>
              {fieldHasErrors(errors, "ts_" + timeslot.id) &&
                <Row>
                  <Col className="text-center mb-3 usacm-error-message">
                    {getErrorMessageForField(errors, "ts_" + timeslot.id)}
                  </Col>
                </Row>
              }
            </Col>
            {allowSingleTimeslotKeynotes &&
              <Col className="col-1">
                <Form.Check
                  type="checkbox"
                  label=""
                  id="keynote_"
                  checked={getKeynoteFlag(timeslot.id)}
                  onChange={e => setKeynoteFlag(timeslot.id, e.target.checked)}
                />
              </Col>
            }
          </Row>
        })}

        {anyFieldHasErrors(errors, ['']) &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
        }

        <Row>
          <Col className="usacm-button-row">
            <Button variant="secondary" onClick={() => onClose()}>{symposiumLabelUpper} List</Button>
            <Button type="button" onClick={() => downloadFile(downloadUrl, 'sessions.xlsx')} className="ms-2 text-nowrap">
              Download All Sessions <FontAwesomeIcon icon="fa-file-excel" className="ms-2"/>
            </Button>
            {!symposium?.scheduling_complete &&
              <Button onClick={() => showSchedulingCompleteConfirm()}>Scheduling Complete</Button>
            }
            {symposium?.scheduling_complete && isStaffOrAdmin &&
              <Button onClick={() => callUpdateSchedulingComplete(false)}>Allow Scheduling</Button>
            }
            {canEdit &&
              <Button onClick={() => callUpdate()}>Update</Button>
            }
            <ForceUpdateButton errors={errors} onClick={() => callUpdate(true)}/>
          </Col>
        </Row>

      </Container>

      <Modal show={showingInstructions}
             onHide={() => setShowingInstructions(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Scheduling Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="" dangerouslySetInnerHTML={{__html: schedulingInstructionsHtml}}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingInstructions(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showingSchedulingCompleteConfirm}
             onHide={() => setShowingSchedulingCompleteConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{symposiumLabelUpper} Scheduling Complete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you have finished scheduling abstracts in this {symposiumLabel} for all sessions? </p>
          <p className='mt-3'>
            <span className='text-warning fw-bold'>Warning!</span>
            <span className='ms-3'>
            You will no longer be able to change the schedule for any sessions in this {symposiumLabel} once you mark scheduling as
            complete.
            </span>
          </p>
          <p className='mt-3'>
            Please note that unsaved changes on this screen will not be saved, you need to click "Update" to save changes before marking
            scheduling as complete.
          </p>
          {anyFieldHasErrors(schedulingCompleteErrors, ['']) &&
            <div className="mt-3 text-center usacm-error-message">
              {getErrorMessageForField(schedulingCompleteErrors, '')}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingSchedulingCompleteConfirm(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => callUpdateSchedulingComplete(true)}>Scheduling Complete</Button>
        </Modal.Footer>
      </Modal>

    </Fragment>
  );
}

